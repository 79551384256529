.app__menuitem {
  width: 100%;
  margin: 1rem 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.app__menuitem-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app__menuitem-name .app__submenuitem-name {
  margin: auto;
  margin-left: 1rem;
  margin-bottom: 0.1rem;
  font-weight: 500;
  line-height: 3;
}
.app__submenuitem-name {
  margin-left: 4rem;
}

.app__menuitem-meaning {
  display: none;
  font-family: var(--font-base);
  margin: auto;
  margin-left: 1rem;
  font-size: 0.85rem;
  font-style: italic;
  line-height: 1.2;
}
.app__menuitem-ingredients {
  font-family: var(--font-base);
  margin: auto;
  margin-left: 2rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  line-height: 1.2;
}

.app__submenuitem-ingredients {
  font-family: var(--font-base);
  margin: auto;
  margin-left: 6rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  line-height: 1.2;
}

.app__menuitem-dash {
  width: 70%;
  height: 1px;
  background: var(--color-golden);
  align-self: center;
}

.app__menuitem-price {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.app__menuitem-course {
}
.app__menuitem-sub {
  width: 100%;
  margin-top: 0.2rem;
}

@media screen and (min-width: 2000px) {
  .app__menuitem-name {
    margin: -1rem;
    margin-top: 0rem;
    margin-left: 1rem;
  }
  .app__menuitem-ingredients {
    margin: auto;
    margin-left: 2rem;
    margin-top: 0.1rem;
    font-size: 1rem;
    line-height: 1.2;
  }
}

@media screen and (max-width: 2000px) {
  .app__menuitem-name {
    margin: auto;
    margin-left: 1rem;
    line-height: 1;
  }
  .app__menuitem-ingredients {
    margin: auto;
    margin-left: 2rem;
    margin-top: 0.1rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 650px) {
  .app__menuitem-name {
    margin: auto;
    margin-left: 0rem;
    line-height: 1;
  }
  .app__menuitem-ingredients {
    margin: auto;
    margin-left: 1.25rem;
    margin-top: 0.1rem;
    font-size: 1rem;
    line-height: 1.75;
  }
  .app__submenuitem-ingredients {
    margin: auto;
    margin-left: 6rem;
    margin-top: 0.1rem;
    font-size: 1rem;
    line-height: 1.2;
  }
}
