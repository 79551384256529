.app__specialMenu {
  flex-direction: column;
  background: var(--color-wabkg);
}

.app__specialMenu-title {
  margin-bottom: 0.1rem;
  text-align: center;
}

.app__specialMenu-menu {
  width: 100%;
  padding: 1rem;
  margin: 1rem;

  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  align-items: center;
  flex-direction: row;
}

.app_specialMenu-menu_price {
  display: flex;
  flex: 1 1 0;
}

.app_specialMenu-menu_price_text {
  font-family: var(--font-base);
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: var(--color-white);
  margin-left: 1rem;
  margin-right: 1rem;
}

.app__specialMenu-menu_heading {
  font-family: var(--font-base);
  font-weight: 750;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: var(--color-white);
}

.app__specialMenu-menu_category {
  font-family: var(--font-base);
  font-weight: 100;
  font-size: 20px;
  line-height: 45px;
  letter-spacing: 0.04em;
  color: var(--color-white);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 2rem;
  margin-left: 3rem;
}

.app__specialMenu-menu_warning {
  font-family: var(--font-base);

  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: var(--color-grey);
}

.app__specialMenu-menu_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__specialMenu-menu_img {
  width: 75%;
  margin: 1rem;
}

.app__specialMenu-menu_img img {
  width: 100%;
  height: auto;
}

.app__specialMenu_menu_items {
  display: flex;
  flex-direction: column;

  margin: 2rem 0;
  width: 100%;
}

@media screen and (min-width: 2000px) {
  /* .app__specialMenu-menu_img {
    width: 650px;
  }

  .app__specialMenu-menu_img img {
    height: 920px;
  } */
}

@media screen and (max-width: 1150px) {
  .app__specialMenu-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  /* .app__specialMenu-menu_img {
    display: none;
  } */
}

@media screen and (max-width: 650px) {
  /* .app__specialMenu-menu_img {
    display: none;
  } */

  .app__specialMenu-menu_heading {
    font-size: 35px;
    line-height: 48.5px;
  }

  .app__specialMenu-menu_category {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0rem;
    margin-left: 0.5rem;
  }
}

.app__specialMenu-dash {
  width: 100%;
  height: 1px;
  background: var(--color-golden);

  margin-bottom: 2rem;
}
