.app_Contact_bg {
  background: url('../../assets/images/bg_contact.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app_Contact_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  min-height: 100vh;
}

.app_Contact {
  flex-direction: column;
  /* background: var(--color-wabkg); */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  min-height: 100vh;
}

.app_Contact-title {
  margin-bottom: 2rem;
  text-align: center;
}

.app_Contact-menu {
  width: 100%;
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.app_Contact-menu_price {
  display: flex;
  flex: 1 1 0;
}

.app_Contact-menu_price_text {
  font-family: var(--font-base);
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: var(--color-white);
  margin-left: 1rem;
  margin-right: 1rem;
}

.app_Contact-menu_heading {
  font-family: var(--font-base);
  font-weight: 350;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: var(--color-white);
}

.app_Contact-menu_category {
  font-family: var(--font-base);
  font-weight: 100;
  font-size: 20px;
  line-height: 45px;
  letter-spacing: 0.04em;
  color: var(--color-white);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 2rem;
  margin-left: 3rem;
}

.app_Contact-menu_warning {
  font-family: var(--font-base);

  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: var(--color-grey);
}

.app_Contact-menu_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.app_Contact-menu_wine,
.app_Contact-menu_cocktails {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app_Contact-menu_img {
  width: 410px;
  margin: 0 2rem;
}

.app_Contact-menu_img img {
  width: 2%;
  height: 2%;
  margin-top: 15%;
  margin-left: 50%;
}

.app_Contact_menu_items {
  display: flex;
  flex-direction: column;

  margin: 2rem 0;
  width: 100%;
}

.app_Contact-dash {
  display: none;
}

.app_Contact-Group_img {
  width: 30%;
  height: auto;
}

@media screen and (min-width: 2000px) {
  .app_Contact-menu_img {
    width: 650px;
  }

  .app_Contact-menu_img img {
    height: 920px;
  }
  .app_Contact-Group_img {
    width: 30%;
    height: auto;
  }
}

@media screen and (max-width: 1150px) {
  .app_Contact-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .app_Contact-menu_img {
    display: none;
  }

  .app_Contact-Group_img {
    width: 30%;
    height: auto;
  }
  .app_Contact-dash {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--color-golden);

    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .app_Contact-menu_img {
    display: none;
  }

  .app_Contact-menu_heading {
    font-size: 35px;
    line-height: 48.5px;
  }

  .app_Contact-menu_category {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0rem;
    margin-left: 0.5rem;
  }

  .app_Contact-Group_img {
    width: 50%;
    height: auto;
  }

  .app_Contact-dash {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--color-golden);

    margin-bottom: 2rem;
  }
}
