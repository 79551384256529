.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url('../../assets/images/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.app__header {
  background-color: var(--color-wabkg);
  background-image: url('../../assets/images/bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
}

.app__header-logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.app__header-logo img {
  width: 350px;
  margin-left: 4rem;
  margin-top: 2rem;
}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 600;
  /* text-shadow: 2px 2px var(--color-gray); */
  letter-spacing: 0.05em;
  text-transform: capitalize;
  line-height: 117px;
  font-size: 35px;
  animation: fadeIn 2s;
  margin-left: 3rem;
  margin-bottom: 1rem;
  margin-top: 10rem;
}

.app__header-img {
  /* width: 80%; */
  display: none;
}

@media screen and (min-width: 2000px) {
  .app__header-h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .app__header-logo img {
    width: 400px;
    margin-left: 2rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 30px;
  }
  .app__header-logo img {
    width: 350px;
    margin-left: 2rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .container {
    background-image: url('../../assets/images/bg_portrait.jpg');
  }
  .app__header-h1 {
    font-size: 22px;
    line-height: 22px;
  }
  .app__header-logo img {
    width: 300px;
    margin-left: 2rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .container {
    background-image: url('../../assets/images/bg_portrait.jpg');
  }
  .app__header-h1 {
    font-size: 18px;
    line-height: 18px;
  }
  .app__header-logo img {
    width: 250px;
    margin-left: 2rem;
    margin-top: 1rem;
  }
}

.app__header-h2 {
}
