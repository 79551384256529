.box {
  background-color: var(--color-golden); /* Set the background color to gray */
  display: inline-block; /* Make the box an inline element */
  padding: 0.5rem 1rem; /* Add some padding to the box */
}

.app__jobs {
  flex-direction: column;
  background: var(--color-wabkg);
}
